import type { PropsWithChildren } from 'react'
import { useRouter } from 'next/router'

import { Box, Divider } from '@mui/material'

import {
  AreaOfApplicationFacet,
  DivisionFacet,
  DocumentTypeFacet,
  FieldOfApplicationFacet,
  LanguageFacet,
  ProdCategoryFacet,
  ResetAllButton,
  StoreAvailabilityFacet,
} from '../widgets'

export const PANEL_ENTRIES = [
  { Component: ResetAllButton, dataCy: 'reset-all-filters-button' },
  { Component: DocumentTypeFacet, dataCy: 'document-type' },
  { Component: ProdCategoryFacet, dataCy: 'product-category' },
  { Component: FieldOfApplicationFacet, dataCy: 'field-of-application' },
  { Component: AreaOfApplicationFacet, dataCy: 'area-of-application' },
  { Component: StoreAvailabilityFacet, dataCy: 'store-availability' },
  { Component: DivisionFacet, dataCy: 'division' },
  { Component: LanguageFacet, dataCy: 'language' },
]

type EntryLayoutProps = {
  hasMarginTop: boolean
} & PropsWithChildren

export const EntryLayout: React.FC<EntryLayoutProps> = ({ hasMarginTop, children }) => {
  return (
    <>
      <Box marginBottom={2.3} marginTop={hasMarginTop ? 2.3 : 0}>
        {children}
      </Box>
      <Divider />
    </>
  )
}

export const PanelEntries = () => {
  const { locale } = useRouter()
  const displayStoreAvailabilityFacet = (Component: React.FC) => {
    return !(Component === StoreAvailabilityFacet && locale !== 'de-DE')
  }
  return (
    <>
      {PANEL_ENTRIES.map(
        ({ Component, dataCy }) =>
          displayStoreAvailabilityFacet(Component) && (
            <EntryLayout key={dataCy} hasMarginTop={dataCy !== 'location'}>
              <Component dataCy={dataCy} />
            </EntryLayout>
          ),
      )}
    </>
  )
}

import { Box } from '@mui/material'

import { PanelEntries } from './PanelEntries'

const styles = {
  width: '330px',
  paddingRight: 4,
  paddingBottom: 4,
}

export const DesktopFilters = () => {
  return (
    <Box sx={styles}>
      <PanelEntries />
    </Box>
  )
}
